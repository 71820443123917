import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class LoginService implements Resolve<any>{

	onTutorialsChanged: BehaviorSubject<any>;
	onUserChanged: BehaviorSubject<any>;

	/**
	 * constructor()
	 * @param {HttpClient} _httpClient
	 */
	constructor(
		private _httpClient: HttpClient,
		private _router: Router){
		this.onTutorialsChanged = new BehaviorSubject([]);
		this.onUserChanged = new BehaviorSubject({});
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		let token = route.paramMap.get('token');
		if(token)
			return new Promise((resolve,reject) => {
				Promise.all([
						this.activateAccount(token),
						this.getTutorials()
					]).then(response => {
						resolve(response);
					}).catch(error => {
						this._router.navigate(['invalid-link']);
					})
			})
		else
			return new Promise((resolve,reject) => {
				Promise.all([
						this.getTutorials()
					]).then(response => {
						resolve(response);
					}).catch(error => {
						console.log(error);
					})
			})
	}

	activateAccount(token){
		let headers = new HttpHeaders({
			'token': token
		})
		return new Promise((resolve,reject) => {
			this._httpClient.put('user/activate',{},{headers: headers, observe: 'response'})
				.subscribe(response => {
					this.onUserChanged.next(response);
					resolve(response);
				},reject)
		})
	}

	/**
	 * login() - calls login API with request object contaning email and password
	 *
	 * @param {JSON} loginData
	 * @returns {Promise}
	 */
	login(loginData): Promise<any> | any {
		let httpHeaders = new HttpHeaders({
			'authorization' : 'Basic ' + btoa(loginData.email + ':' + loginData.password),
		});
		return new Promise((resolve,reject) => {
			this._httpClient.post('User/login','', {headers: httpHeaders, observe: 'response'})
				.subscribe(response => {
					resolve(response);
				},reject)
		})
	}

	getTutorials(): Promise<any> | any{
		return new Promise((resolve,reject) => {
			this._httpClient.get('Tutorials')
				.subscribe((response: any) => {
					this.onTutorialsChanged.next(response.data);
					resolve(response.data);
				},reject)
		})
	}

	getPaymentChackoutSession(authorization,planId){
		let header = new HttpHeaders({
			'Authorization': authorization
		})
		return new Promise((resolve,reject) => {
			this._httpClient.post('payments/create-checkout-session?planId=' + planId, {}, {headers: header})
				.subscribe(response => {
					resolve(response);
				})
		})
	}


}