import { Component } from '@angular/core';
import { Router } from '@angular/router';
import '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { DeviceDetectorService } from 'ngx-device-detector';

loadStripe.setLoadParameters({advancedFraudSignals: false});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'YOK';

  isDesktop: boolean = true;

  constructor(
    private _router: Router,
    private _deviceDetectorService: DeviceDetectorService){

  }

  ngOnInit(){
    // this.isDesktop = this._deviceDetectorService.isDesktop();
  	this._router.events
    .subscribe(() => {
  		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      document.body.style.zoom = '100%';
      
  	})
  }

}
