import { IQMUser, GoogleUser, FacebookUser, TiktokUser } from './third-party-auth.model';
export class User {
	firstName: any;
	lastName: any;
	userName: any;
	userId: any;
	passwordHash: any;
	passwordSalt: any;
	isEmailConfirmed: any;
	userRole: UserRole;
	roleId: any;
	facebookId: any;
	facebookUser: any;
	facebookLinkStatus: boolean;
	googleId: any;
	googleUser: any;
	googleAdsLinkStatus: boolean;
	iqmId: any;
	iqmUser: any;
	iqmLinkStatus: boolean;
	spotifyId: any;
	spotifyUser: any;
	amazonId: any;
	amazonUser: any;
	userPlan: UserPlan;
	privacyAccepted: boolean;
	firstTimeLogin: boolean;
	allowedCampaignsCount: number;
	businessName: string;
	userAddress: UserAddress;
	tiktokId: any;
	tikTokUser: any;
	tiktokLinkStatus: boolean;

	constructor(userData?){
		this.firstName = userData.firstName;
		this.lastName = userData.lastName;
		this.userName = userData.userName;
		this.userId = userData.userId;
		this.passwordHash = userData.passwordHash;
		this.passwordSalt = userData.passwordSalt;
		this.isEmailConfirmed = userData.isEmailConfirmed;
		this.userRole = userData.userRole ? new UserRole(userData.userRole) : new UserRole();
		this.roleId = userData.roleId || "";
		this.facebookId = userData.facebookId || "";
		this.facebookUser = userData.facebookUser ? new FacebookUser(userData.facebookUser) : new FacebookUser();
		this.facebookLinkStatus = userData.facebookLinkStatus || false;
		this.googleId = userData.googleId || "";
		this.googleUser = userData.googleUser ? new GoogleUser(userData.googleUser) : new GoogleUser();
		this.googleAdsLinkStatus = userData.googleAdsLinkStatus || false;
		this.iqmId = userData.iqmId|| "";
		this.iqmUser = userData.iqmUser ? new IQMUser(userData.iqmUser) : new IQMUser();
		this.iqmLinkStatus = userData.iqmLinkStatus || false;
		this.spotifyId = userData.spotifyId|| "";
		this.spotifyUser = userData.spotifyUser|| null;
		this.amazonId = userData.amazonId || null;
		this.amazonUser = userData.amazonUser || null;
		this.userPlan = userData.userPlan ? new UserPlan(userData.userPlan) : new UserPlan();
		this.privacyAccepted = userData.privacyAccepted || false;
		this.firstTimeLogin = userData.firstTimeLogin || false;
		this.allowedCampaignsCount = userData.allowedCampaignsCount || null;
		this.businessName = userData.businessName || "";
		this.userAddress = userData.userAddress ? new UserAddress(userData.userAddress) : new UserAddress();
		this.tiktokId = userData.tiktokId || "";
		this.tikTokUser = userData.tikTokUser ? new TiktokUser(userData.tikTokUser): new TiktokUser();
		this.tiktokLinkStatus = userData.tiktokLinkStatus || false;
	}
}
export class UserRole {
	roleId: any;
	roleName: any;
	constructor(userRole?){
		this.roleId = userRole.roleId || "";
		this.roleName = userRole.roleName || "";
	}
}

export class UserPlan {
	currentStatus: number;
	planId: number;
	endDate: string;
	startDate: string;
	paymentMethodId: string;
	paymentCardNumber: string;
	paymentCardName: string;

	constructor(userPlan?){
		userPlan = userPlan || {};
		this.currentStatus = userPlan.currentStatus || null;
		this.planId = userPlan.planId || null;
		this.endDate = userPlan.endDate || "";
		this.startDate = userPlan.startDate || "";
		this.paymentMethodId = userPlan.paymentMethodId || "";
		this.paymentCardNumber = userPlan.paymentCardNumber || "";
		this.paymentCardName = userPlan.paymentCardName || "";
	}
}

export class UserAddress {
	street: string;
	city: string;
	state: string;
	zip: string;

	constructor(userAddress?){
		userAddress = userAddress || {};
		this.street = userAddress.street || "";
		this.city = userAddress.city || "";
		this.state = userAddress.state || "";
		this.zip = userAddress.zip || "";
	}
}
