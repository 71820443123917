import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MasterModule } from './master/master.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptHttp } from './interceptors/http.interceptor';
import { ClientsService } from './master/clients/clients.service';
import { UserService } from './service/user.service';
import { AuthService } from './service/auth.service';
import { SpinnerService } from './service/spinner.service';
import { LoginService } from './login/login.service';
import { CanDeactivateGuard } from './service/guard-routes.service';
import { PrivacyPolicyService } from './privacy-policy/privacy-policy.service';
import { PlansManagementService } from './plans-management/plans-management.service';
 
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptHttp, multi: true }, UserService, AuthService, SpinnerService, LoginService, CanDeactivateGuard, PrivacyPolicyService, PlansManagementService]
})
export class AppModule { }
