import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {

	show: number = 0;

	constructor(){

	}

	showSpinner(){
		this.show += 1;
	}

	hideSpinner(){
		this.show -= 1;
	}
}