import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PlansManagementService {

	headers : HttpHeaders;
	onPaymentMethodsChanged: BehaviorSubject<any>;
	onCustomerDetailsChanged: BehaviorSubject<any>;
	clients: Array<any>;


	
	constructor(
		private _httpClient: HttpClient,
		private _authService: AuthService) {
		this.onPaymentMethodsChanged = new BehaviorSubject({});
		this.onCustomerDetailsChanged = new BehaviorSubject({});
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		this.setHeaders();
		this.getPaymentMethods();
		return new Promise((resolve,reject) => {
			Promise.all([
				this.getCustomerDetails()
				]).then(response => {
					resolve(response);
				},reject)
		})
	}

	setHeaders(){
		this.headers = new HttpHeaders({
			'Authorization': this._authService.getAuthorizationToken()
		})
	}

	getCustomerDetails(){
		return new Promise((resolve,reject) => {
			this._httpClient.get('payments/get-customer',{headers: this.headers})
				.subscribe((response: any) => {
					this.onCustomerDetailsChanged.next(response);
					resolve(response);
				},reject)
		})
	}

	getPaymentMethods(){
		return new Promise((resolve,reject) => {
			this._httpClient.get('payments/customer-cards',{headers: this.headers})
				.subscribe((response: any) => {
					this.onPaymentMethodsChanged.next(response);
					resolve(response);
					console.log(JSON.parse(response.data));
				},reject)
		})
	}

	addPaymentMethods(token){
		return new Promise((resolve,reject) => {
			this._httpClient.post('payments/add-card?token=' + token,{},{headers: this.headers})
				.subscribe((response: any) => {
					this.getPaymentMethods();
					this.getCustomerDetails();
					resolve(response);
				},reject)
		})
	}

	deleteCard(cardId){
		return new Promise((resolve,reject) => {
			this._httpClient.delete('payments/remove-card?card_id=' + cardId,{headers: this.headers})
				.subscribe((response: any) => {
					this.getPaymentMethods();
					this.getCustomerDetails();
					resolve(response);
				})
		})
	}

	updateCustomerDetails(customerDetails){
		return new Promise((resolve,reject) => {
			this._httpClient.post('payments/update-customer-details',customerDetails,{headers: this.headers})
				.subscribe((response: any) => {
					this.getPaymentMethods();
					this.getCustomerDetails();
					resolve(response);
				},reject)
		})
	}

	cancelPlan(){
		return new Promise((resolve,reject) => {
			this._httpClient.delete('payments/cancel-subscription',{headers: this.headers})
				.subscribe((response: any) => {
					resolve(response);
				},reject)
		})
	}

	updatePlan(planId): Promise<any>{
		this.setHeaders();
		return new Promise((resolve,reject) => {
			this._httpClient.post('payments/update-subscription?planId=' + planId,{},{headers: this.headers})
				.subscribe((response: any) => {
					this.getPaymentMethods();
					this.getCustomerDetails();
					resolve(response);
				},reject)
		})
	}

	updateToRecurringPlan(planId){
		return new Promise((resolve,reject) => {
			this._httpClient.post('payments/create-subscription?planId=' + planId,{},{headers: this.headers})
				.subscribe((response: any) => {
					this.getPaymentMethods();
					this.getCustomerDetails();
					resolve(response);
				},reject)
		})
	}

	getPaymentChackoutSession(planId){
		return new Promise((resolve,reject) => {
			this._httpClient.post('payments/create-checkout-session?planId=' + planId, {}, {headers: this.headers})
				.subscribe(response => {
					resolve(response);
				})
		})
	}

	getAllClients(): Promise<any> | any{
		return new Promise((resolve,reject) => {
			this._httpClient.get('User/clients', {headers: this.headers})
				.subscribe((response: any) => {
					this.clients = response.data;
					resolve(response.data);
				},reject)
		})
	}

	toggleClientsStatus(clientsUsernames, status){
		return new Promise((resolve,reject) => {
			this._httpClient.put('User/multiple-active-status?status=' + status, clientsUsernames, {headers: this.headers})
				.subscribe((response: any) => {
					resolve(response.data);
				},reject)
		})
	}

	getCouponValidity(): Promise<any> | any{
		this.setHeaders();
		return new Promise((resolve, reject) => {
			this._httpClient.get('payments/getUserPlanCouponValidity', {headers: this.headers})
				.subscribe(response => {
					resolve(response);
				},reject)
		})
	}

}