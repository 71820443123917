const client_keys = {
	spotifyClientId: "d14c880b762d4dd09582c730020cbf36",
	amazonClientId: "amzn1.application-oa2-client.d987fe1cfee64428b87edc5e8c73cd3d",
	googleClientId: "288622338160-9kf6doid4eqr7c8frkktqencgue7q695.apps.googleusercontent.com",
	facebookClientId:"3017610971818464",
}

const GOOGLE_API_KEY = "AIzaSyCs6Koz_JP8wPoCck4S2yD-SmxrgmMvJhY";

export const environment = {
	production: false,
	baseUrl: 'https://stagingapi.yokdigital.com/',
	smartLinksUrl: 'https://staging.yoklink.it/',
	spotifyAuthUrl: '',
	iqmUrl: 'https://yokdigital.preprod.iqm.com/api/v3/',
	facebookApiUrl: "https://graph.facebook.com/v14.0/",
	iqmBasicToken : "a2E5a29ha2FpWG9oeDdhaHllZTI6aGFpdzxhaEdo",
	amazonAuthUrl:'',
	googleAuthUrl: "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/adwords&access_type=offline&response_type=code&client_id=" + client_keys.googleClientId + "&prompt=consent&redirect_uri=" + window.location.origin + "/google-auth-callback",
	facebookAuthUrl: "https://www.facebook.com/v14.0/dialog/oauth?client_id=" + client_keys.facebookClientId + "&redirect_uri=" + window.location.origin + "/facebook-auth-callback&response_type=token&scope=ads_management,pages_manage_ads,ads_read,pages_read_engagement,pages_manage_cta,pages_show_list,instagram_basic",
	tikTokAuthUrl: "https://ads.tiktok.com/marketing_api/auth?app_id=7147584952857559042&state=your_custom_params&redirect_uri=https%3A%2F%2Fstaging.yokdigital.com%2Ftiktok-auth-callback&rid=ixrjhpe0gy",
	GOOGLE_MAPS_ID:"18da0333d37f7b3f",
	AWS_ACCESS_KEY_ID: 'AKIAWDPN3AYL7BQCG3PK',
  	AWS_SECRET_ACCESS_KEY: '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',
  	AWS_S3_BUCKET_REGION: 'us-west-2',
	AWS_S3_BUCKET_NAME: "jamvana-ads-creative",
  	STRIPE_PUBLISHABLE_KEY: "pk_test_51HxD0PDzfMRlLJfVyf36kJe8vNwF22oqh40xy27yxnyzgrdWbh9TyXcWDBIPnHAnMk7IzGvPJw9EbMBygQsca0NM00kPSIdibU"
};