import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginService } from './login/login.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: '',
		loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
		resolve: {
			data: LoginService
		}
	},
	{
		path: 'cancel',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
		resolve: {
			data: LoginService
		}
	},
	{
		path: 'activationcode/:token',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
		resolve: {
			data: LoginService
		}
	},
	{
		path: 'sign-up',
		loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule)
	},
	{
		path: 'forgot-password',
		loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
	},
	{
		path: 'resetpassword/:token',
		loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
	},
	{
		path: 'invalid-link',
		loadChildren: () => import('./invalid-link/invalid-link.module').then(m => m.InvalidLinkModule)
	},
	{
		path: 'tutorials-upload-panel',
		loadChildren: () => import('./tutorials-upload-panel/tutorials-upload-panel.module').then(m => m.TutorialsUploadPanelModule)
	},
	{
		path: 'success',
		loadChildren: () => import('./payment-success-callback/payment-success-callback.module').then(m => m.PaymentSuccessCallbackModule)
	},
	{
		path: 'plan-management',
		loadChildren: () => import('./plans-management/plans-management.module').then(m => m.PlansManagementModule)
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
	},
	{
		path: 'terms-and-conditions',
		loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
	},
	{
		path: 'iqm/callback',
		loadChildren: () => import('./iqm-callback/iqm-callback.module').then(m => m.IqmCallbackModule)
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
