import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError, finalize } from 'rxjs/operators';
import { SpinnerService } from '../service/spinner.service';

@Injectable()
export class InterceptHttp implements HttpInterceptor{

	/**
	 * Constructor
	 */
	 constructor(private _spinnerService: SpinnerService){

	 }


	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if(!window.navigator.onLine){
			this.showSnackBar("You are offline.")
			return EMPTY;
		}
		var url = req.url;
		let lastResponse: HttpEvent<any>;
		let errorResponse: HttpErrorResponse; 
		let showSpinner: boolean = (url.split('?')[0] == "FacebookCampaign/adgeolocation" && url.split('?')[0] == "googlecampaign/location");
	 	if(url == 'iqm/signin'){
	 		url = environment.iqmUrl + 'ua/login';
	 	}else if(!req.headers.get('Authorization') && url != 'user/register' && url != 'user/activate' && url != 'user/forgotpassword' && url!= 'User/resetpassword' && url != 'User/login' && url != 'Tutorials' && url != 'user/login-with-iqm'){
	 		url = url; 
	 	}
	 	else {
	 		url = environment.baseUrl + url
	 	}
	 	const _req = req.clone({ url: url });
	 	this._spinnerService.showSpinner();
	 	return next.handle(_req)
	 		.pipe(
	 			map((event: HttpEvent<any>) => {
					lastResponse = event;
	 				if(event instanceof HttpResponse){
	 					this._spinnerService.hideSpinner();
	 				}
	 				return event;
	 			}),
				catchError((error: HttpErrorResponse) => {
					errorResponse = error;
					this._spinnerService.hideSpinner();
					this.displayError(error);
					return throwError(error);
				}),
				finalize(() => {
					if(lastResponse.type === HttpEventType.Sent && !errorResponse){
						this._spinnerService.hideSpinner();
					}
				})
			)

	}

	displayError(error: HttpErrorResponse){
		switch(error.status){
			case 404:
				this.showSnackBar("Some data for this page has not been found")
			break;
			case 403:
				this.showSnackBar("Access Denied: You may not have access to some information")
			break;
			case 500:
				this.showSnackBar("Something went wrong, we're working on it.")
			break;
			case 401:
				{
					if (error.error.message)
						this.showSnackBar(error.error.message)
					else
						this.showSnackBar("Unauthorised access.")
				}
				break;
			default:
				this.showSnackBar("Unknown Server Error")
			break;
		}
	}

	/**
	 * showSnackBar() - called to show the toast message
	 */
	 showSnackBar(msg){
		var x = document.getElementById("httpSnackbar");
		x.className = "show snackbar-right";
		x.innerHTML = msg;
		setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	}

}