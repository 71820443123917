import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import Swal from 'sweetalert2';

@Injectable()
export class PrivacyPolicyService implements Resolve<any>{

	headers: HttpHeaders;
	// showButton: boolean;

	/**
	 * constructor()
	 * @param {HttpClient} _httpClient
	 */
	constructor(
		private _httpClient: HttpClient,
		private _authService: AuthService,
		private _router: Router,
		private _userService: UserService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.setHeaders();
	}

	setHeaders() {
		this.headers = new HttpHeaders({
			'Authorization': this._authService.getAuthorizationToken()
		})
	}

	acceptPrivacyPolicy(userName): Promise<any> | any {
		return new Promise((resolve, reject) => {
			this._httpClient.put("User/acceptPrivacyPolicy/" + userName, {}, { headers: this.headers })
				.subscribe(response => {
					resolve(response);
				}, reject)
		})
	}
}