import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

	constructor(){

	}

	setAuthorizationToken(authorization){
		localStorage.setItem('authorization',authorization);
	}

	getAuthorizationToken(){
		return localStorage.getItem('authorization');
	}
}