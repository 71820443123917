export class FacebookUser{

	accountId: any;
	adAccountId: any;
	token: any;
	isLinked: boolean;

	constructor(facebookUser?){
		facebookUser = facebookUser || {};
		this.adAccountId = facebookUser.adAccountId || "";
		this.accountId = facebookUser.accountId || "";
		this.token = facebookUser.facebookToken || facebookUser.token || "";
		this.isLinked = facebookUser.isLinked;
	}
}

export class IQMUser{

	email: string;
	password: string;
	token: string;
	refreshToken: string;
	owId: number;
	isLinked: boolean;

	constructor(iqmUser?){
		iqmUser = iqmUser || {};
		this.email = iqmUser.email || "";
		this.password = iqmUser.password || "";
		this.token = iqmUser.iqmToken || iqmUser.token || "";
		this.refreshToken = iqmUser.accessToken || iqmUser.refreshToken || "";
		this.owId = iqmUser.owId || null;
		this.isLinked = iqmUser.isLinked;
	}
}

export class GoogleUser{
	accountId: string;
	token: string;
	isLinked: boolean;
	constructor(googleUser?){
		googleUser = googleUser || {};
		this.accountId = googleUser.accountId || "";
		this.token = googleUser.googleToken || googleUser.token || "";
		this.isLinked = googleUser.isLinked;
	}
}


export class TiktokUser{
	accountId: string;
	token: string;
	isLinked: boolean;
	constructor(tiktokUser?){
		tiktokUser = tiktokUser || {};
		this.accountId = tiktokUser.accountId || "";
		this.token = tiktokUser.tiktokToken || "";
		this.isLinked = tiktokUser.isLinked;
	}
}
