import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

@Injectable()
export class UserService {

	constructor(private _router: Router){
	}

	setLoggedInUser(user: any){
		localStorage.setItem('user',JSON.stringify(user));
	}

	getLoggedInUser(): User {
		let user = JSON.parse(localStorage.getItem("user"));
		if(user && (user.userPlan ? ((user.userPlan.currentStatus == 1) ||  (user.userPlan.currentStatus == 4)): true)){
			return new User(user);
		} else {
			this._router.navigate(['login']);
		}
	}

	getLoggedInUserToManagePlans(): User {
		let user = JSON.parse(localStorage.getItem("user"));
		if(user){
			return new User(user);
		} else {
			this._router.navigate(['login']);
		}
	}

}